<template>
  <div>
    <div class="content-tab border-top-line mb-5">
      <v-container
        fluid
        style="width: 100%"
      >
        <v-row>
          <v-col
            class="pa-0"
            cols="12"
            sm="12"
          >
            <div class="width-100">
              <div class="display-flex align-items-center justify-content-flex-end mb-5">
                <v-btn
                  v-if="isResponsive"
                  @click="addUser"
                  class="button-primary mon-bold"
                  elevation="0"
                  icon
                >
                  <v-icon size="18px">mdi-plus-thick</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  @click="addUser"
                  class="button-add-buyer mon-bold"
                  elevation="0"
                >
                  {{ texts.buyers.buttonAddBuyer }}
                </v-btn>
              </div>
              <div v-if="users.length > 0">
                <div v-if="isResponsive">
                  <div
                    v-for="(item, index) in users"
                    :key="index"
                    class="card-content mb-5"
                  >
                    <p
                      style="font-size: 25px;"
                      class="mon-bold"
                    >{{ item.sName }}</p>
                    <div class="display-flex align-items-center">
                      <p class="mon-bold">{{ texts.buyers.table.headers[1] }}</p>
                      <v-spacer />
                      <p class="mon-regular">{{ item.sLastname }}</p>
                    </div>
                    <div class="display-flex align-items-center">
                      <p class="mon-bold">{{ texts.buyers.table.headers[2] }}</p>
                      <v-spacer />
                      <p class="mon-regular">{{ item.sEmail }}</p>
                    </div>
                    <div class="display-flex align-items-center">
                      <p class="mon-bold">{{ texts.buyers.table.headers[3] }}</p>
                      <v-spacer />
                      <p class="mon-regular">{{ item.sPositionName }}</p>
                    </div>
                    <div class="display-flex align-items-center">
                      <p class="mon-bold">{{ texts.buyers.table.headers[4] }}</p>
                      <v-spacer />
                      <p class="mon-regular">{{ item.sPhoneNumber }}</p>
                    </div>
                    <div>
                      <v-btn
                        @click="editUser(item)"
                        class="button-primary mon-bold"
                        style="width: 100%;"
                        elevation="0"
                      >
                        {{ selectLanguage == 'sp' ? 'Modificar' : 'Modify' }}
                      </v-btn>
                      <v-btn
                        @click="deleteUser(item.sCustomerId)"
                        class="button-tertiary mon-bold mt-5"
                        style="width: 100%;"
                        elevation="0"
                      >
                        {{ selectLanguage == 'sp' ? 'Eliminar' : 'Delete' }}
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  class="card-content"
                >
                  <div class="scrollbar-table">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th
                              v-for="(item, index) in texts.buyers.table.headers"
                              :key="index"
                              class="text-left text-header-title mon-bold"
                            >
                              {{ item }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in users"
                            :key="index"
                          >
                            <td class="mon-regular">{{ item.sName }}</td>
                            <td class="mon-regular">{{ item.sLastname }}</td>
                            <td class="mon-regular">{{ item.sEmail }}</td>
                            <td class="mon-regular">{{ item.sPositionName }}</td>
                            <td class="mon-regular">{{ item.sPhoneNumber }}</td>

                            <td class="mon-regular">
                              <v-btn
                                @click="editUser(item)"
                                icon
                              >
                                <v-icon
                                  color="#414B54"
                                  size="18px"
                                >
                                  mdi-pencil
                                </v-icon>
                              </v-btn>
                              <v-btn
                                @click="deleteUser(item.sCustomerId)"
                                icon
                              >
                                <v-icon
                                  color="#D8686A"
                                  size="18px"
                                >
                                  mdi-delete
                                </v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                  <div class="display-flex align-items-center justify-content-center mt-4 mb-4">
                    <div class="pagination-styles">
                      <v-pagination
                        v-model="iCurrentPage"
                        :length="lengthPage"
                        color="#FFC556"
                        next-icon="mdi-menu-right"
                        prev-icon="mdi-menu-left"
                      ></v-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else
                class="card-content"
                style="height: 220px; display: flex; align-items: center; justify-content: center;"
              >
                <p
                  class="mon-regular"
                  style="text-align: center; font-size: 20px; color: #c1c1c3;"
                >{{ texts.buyers.dialogAddUser.textEmpty }}</p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfileTabContentFourLayout",
  props: {
    texts: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      //VARIABLES
      users: [],
      iCurrentPage: 1,
      lengthPage: 15,
      isResponsive: false,
    };
  },
  beforeMount() {
    this.getUsers();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    deleteUser: function (id) {
      this.$store.commit("setDialogDelete", {
        active: true,
        title: this.texts.buyers.dialogAddUser.textTitleDelete,
        api: `${URI}/api/v1/${this.selectLanguage}/users/${id}`,
        redirect: "",
      });
    },
    addUser: function () {
      this.$store.commit("setProfileDialogAddUser", true);
    },
    editUser: function (item) {
      this.$store.commit("setProfileDialogEditUser", {
        active: true,
        arr: item,
      });
    },
    getUsers: function () {
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$store.state.sEnterpriseId}/employees?iPageNumber=${this.iCurrentPage}&iItemsPerPage=10`,
        config
      )
        .then((response) => {
          this.users = response.data.results;
          this.lengthPage = response.data.iNumPages;
          this.iCurrentPage = response.data.iCurrentPage;

          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.getUsers();
      }
    },
    refresh: function () {
      this.getUsers();
    },
    iCurrentPage: function () {
      this.getUsers();
    },
  },
};
</script>

<style scoped>
.scrollbar-table {
  height: 300px;
  overflow: auto;
}

.scrollbar-table::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-table {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.button-add-buyer {
  background: transparent linear-gradient(258deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  height: 40px !important;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.text-link {
  color: #0971fb;
  text-decoration: underline;
}

.text-link:hover {
  cursor: pointer;
}

.text-header-title {
  color: #000000 !important;
  font-size: 14px !important;
  border-bottom: 1px solid #949494 !important;
}

.card-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  opacity: 1;
  padding: 10px;
}

.content-tab {
  padding: 40px 0px;
}

.border-top-line {
  border-top: 1px solid #cecece;
}

.border-bottom-line {
  border-bottom: 1px solid #cecece;
}
</style>